import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectSistemaTviacomunicacion (Vue, filter, search, sorter, page, idsistema) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (idsistema) {
      apiFilter.addExact('idsistema', idsistema)
    }
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('numero', search)
        .addILike('nabonado', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.numero.value) {
      apiFilter.addILike(filter.numero.field, filter.numero.value)
    }
    if (filter.nabonado.value) {
      apiFilter.addILike(filter.nabonado.field, filter.nabonado.value)
    }
    const resp = await Vue.$api.call('sistemaTviacomunicacion.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSistemaTviacomunicacionRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idsistema_tviacomunicacion', pks)
    const resp = await Vue.$api.call('sistemaTviacomunicacion.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteSistemaTviacomunicacion (Vue, idsistemaTviacomunicacion) {
    await Vue.$api.call('sistemaTviacomunicacion.delete', { idsistema_tviacomunicacion: idsistemaTviacomunicacion })
  },
}
